import React from 'react'
import { FooterBase } from './styles'

function Footer() {
  return (
    <FooterBase>
      {/*<div>Icons made by <a href="https://www.flaticon.com/br/icone-gratis/arquivo_2521541" title="iconixar">iconixar</a> from <a href="https://www.flaticon.com/br/" title="Flaticon">www.flaticon.com</a></div>*/}
     © 2024 TextToCase
    </FooterBase>
  );
}

export default Footer