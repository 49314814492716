import React, { RefObject } from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap; /* This will allow items to wrap into multiple rows */
  justify-content: flex-start; /* Align items to the left */
  gap: 3rem; /* Adjust the gap as necessary */
  padding: 1% 0 1% 0;
  width: 100%;
  margin-bottom: 2rem;

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1200px) and (min-width: 801px) {
    gap: 1rem; /* Adjust the gap for better spacing on tablets */
  }

  /* For screens smaller than 800px (mobile or tablet portrait) */
  @media (max-width: 800px) {
    width: 96%;
    flex-direction: column; /* Stack items in a column */
    gap: 1rem;
  }
`;

interface StyledColProps {
  size: number;
}

export const Col = styled.div<StyledColProps>`
  flex: ${(props) => props.size};
  padding: 0.5%;
`;

export const Card = styled.div`
  padding: 0 2rem 0 2rem;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 50px;
  width: 26%;
  box-shadow: 0 0 1px 1px var(--greyDark);

  @media (max-width: 800px) {
    width: auto;
    margin-bottom: 2rem;
  }

  h3 {
    text-align: center;
  }
`;

export const CardSecondary = styled.div`
  padding: 0 2rem 0 2rem;
  background-color: var(--purple);
  border: 1px solid var(--black);
  border-radius: 50px;
  width: 26%;
  box-shadow: 0 0 1px 1px var(--greyDark);

  @media (max-width: 800px) {
    width: auto;
    margin-bottom: 2rem;
  }

  h3 {
    text-align: center;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: -30px;
  font-size: 28px;

  span {
    background-color: var(--black);
    color: var(--white);
    border-radius: 50%;
    padding: .5rem 1rem;
    min-width: 34px;
  }
`;

interface TutorialProps {
  tutorialRef?: RefObject<HTMLDivElement>;
}

export const Tutorial: React.FC<TutorialProps> = ({ tutorialRef }) => {
  return (
    <>
      <Row ref={tutorialRef}>
        <Col size={1}>
          <p>
            Need to switch up your text from lower case to upper case? 
            Or maybe throw in some funky mix case for fun? 
            With TextToCase, you can easily capitalize, uncapitalize, and give
            your words a whole new vibe. Explore all the options below.
          </p>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardIcon><span>#1</span></CardIcon>
          <h3>Upper Case</h3>
          <p>
            Ready to make your text shout in capital letters? 
            <br />The upper case transformer turns all your text into big, bold capitals.  
            Just paste your text into the box above, hit the ‘UPPER CASE’ tab, 
            and voilà – every letter is now in uppercase glory!
          </p>
          <p>THIS IS AN EXAMPLE OF UPPER CASE</p>
        </Card>
        <CardSecondary>
          <CardIcon><span>#2</span></CardIcon>
          <h3>Title Case</h3>
          <p>Not sure how to format your next essay title? 
          <br />Title case makes sure the right words are capitalized, like 'Title,' 
          while leaving smaller words like 'of' in lowercase. 
          Just paste your text, hit the ‘Title Case’ tab, and get a perfectly polished title 
          in no time!</p>
          <p>
            This Is an Example of Title Case
          </p>
        </CardSecondary>
        <Card>
          <CardIcon><span>#3</span></CardIcon>
          <h3>Lower Case</h3>
          <p>Wondering how to uncapitalize your text? 
          <br />The lower case converter is here to help – it turns all your letters into 
          lowercase in a snap. Just copy your text, paste it into the box above, 
          and hit the ‘lower case’ tab. Watch as your text transforms into a smooth sea of lowercase letters!</p>
          <p>
            this is an example of lower case
          </p>
        </Card>
      </Row>
      <Row>
        <Card>
        <CardIcon><span>#4</span></CardIcon>
        <h3>Sentence Case</h3>
          <p>
            With sentence case converter, you can easily paste 
            any text and watch it transform into perfectly structured sentences. 
            It capitalizes the very first letter of each sentence, turns the rest 
            into lowercase, and switches i’s to I’s. 
            Plus, every letter after a full stop gets an uppercase boost. 
            Just a heads-up: it won’t capitalize names or places. Give it a try and see how your text can shine in sentence case!
          </p>
          <p>
            This is an example of sentence case
          </p>
        </Card>
        <CardSecondary>
          <CardIcon><span>#5</span></CardIcon>
          <h3>Capitalized Case</h3>
          <p>
          Want every word to start with a capital letter?
          Capitalized case turns the first letter of every word into uppercase while 
          keeping the rest in lowercase. Just copy your text, paste it into the box above,
          and hit the ‘Capitalized Case’ tab.
          </p>
          <p>
            This Is An Example Of Capitalized Case
          </p>
        </CardSecondary>
      </Row>
    </>
  )
}

export default Tutorial