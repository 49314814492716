import React, { useState } from 'react';
import Button from '../ui/Button';
import FormField from '../ui/FormField';
// import Toast from '../Toast';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BoxForm, Container } from './styles';
import { titleCase, capitalCase, spongeCase } from 'change-case-all';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;

  @media(max-width: 800px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

interface StyledColProps {
  size: number;
}


export const Col = styled.div<StyledColProps>`
  flex: ${(props: any) => props.size};
  padding: 0.5%;
`;

const notify = (msg: string) => toast.error(msg)

const notifyCopied = (msg: string) => toast.success(msg)

const CaseConverter = () => {
  const [inputText, setInputText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setInputText(value)
  }

  const handleImgClick = () => {
    if (!inputText) {
      notify("Input text is empty!")
      return
    }
    
    navigator.clipboard.writeText(inputText)
      .then(function() {
        notifyCopied("Copied to clipboard!")
      }, function() {
        notify("Error trying to copy!")
      });
    
  }

  const toUpperCase = () => {
    if (hasInputText(inputText)) {
      const text = inputText.trim()
      setInputText(text.toUpperCase())
    }
  }
  
  const toLowerCase = () => {
    if (hasInputText(inputText)) {
      const text = inputText.trim()
      setInputText(text.toLowerCase())
    }
  }

  const toTitleCase = () =>{
    if (hasInputText(inputText)) {
      // Split text by line breaks
      const lines = inputText.split('\n');
      
      // Apply titleCase to each line individually
      const updatedText = lines.map(line => titleCase(line.toLocaleLowerCase())).join('\n');
      
      // Update the inputText with the modified text
      setInputText(updatedText);
    }
  }
  
  const toSentenceCase = () => {
    if (hasInputText(inputText)) {
      // Convert the entire text to lowercase
      const lowerCaseText = inputText.toLowerCase();
      // Split text by line breaks
      const lines = lowerCaseText.split('\n');
        
      // Function to capitalize the first letter of each sentence
      const processLine = (line: string) => {
        // Capitalize the first letter of the line
        line = line.charAt(0).toUpperCase() + line.slice(1);
        
        // Capitalize the first letter after sentence-ending punctuation
        return line.replace(/([.!?])([^\s])/g, (match, p1, p2) => p1 + p2.toUpperCase())
                   .replace(/([.!?])\s+([^\s])/g, (match, p1, p2) => p1 + ' ' + p2.toUpperCase());
      };

      // Process each line individually
      const updatedText = lines.map(line => processLine(line)).join('\n');

      setInputText(updatedText);
    }
  }

  const toCapitalCase = () => {
    if (hasInputText(inputText)) {
      // Split text by line breaks
      const lines = inputText.split('\n');

        // Function to preserve punctuation while capitalizing text
        const processLine = (line: string) => {
          // Preserve special characters like punctuation
          return line.replace(/([^\w\s])|(\w+)/g, (match, p1, p2) => {
              // If it's a punctuation mark, return it as is
              if (p1) return p1;
              // If it's a word, apply capitalCase
              if (p2) return capitalCase(p2);
          });
      };

      // Process each line individually
      const updatedText = lines.map(line => processLine(line)).join('\n');

      setInputText(updatedText);
    }
  }

  const toSpongeCase = () => {
    if (hasInputText(inputText)) {
      const updatedText = spongeCase(inputText);
      setInputText(updatedText);
    }
  }

  const hasInputText = (inputText: string) => {
    if (inputText) {
      return true
    }
    else {
      notify("Input text is empty!")
      return false
    }
  }

  const handleClear = () => {
    setInputText('');
  }

  return (
    <>
      <ToastContainer 
        theme="dark"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <Container>
        <Row>
          {/* <Col size={1}></Col> */}
          <Col size={10}>
            <BoxForm>
              <FormField
                placeholder="Write or paste your text here"
                name="inputText"
                type="textarea"
                onChange={handleChange}
                value={inputText}
                handleImgClick={handleImgClick}
                enableCopy
              />
            </BoxForm>
            <Row>
                <Col size={2}>
                  <Button onClick={toUpperCase}>
                    UPPER CASE
                  </Button>
                </Col>
                <Col size={2}>
                <Button onClick={toLowerCase}>
                    lower case
                  </Button>
                </Col>
                <Col size={2}>
                  <Button onClick={toSentenceCase}>
                      Sentence case
                  </Button>
                </Col>
                <Col size={2}>
                <Button onClick={toTitleCase}>
                    Title Case
                  </Button>
                </Col>
                <Col size={2}>
                  <Button onClick={toCapitalCase}>
                     Capitalized Case
                  </Button>
                </Col>
                {/* <Col size={2}>
                  <Button onClick={toSpongeCase}>
                      aLtErNaTiNg CaSe
                  </Button>
                </Col> */}
                <Col size={1}>
                  <Button onClick={handleClear}>
                      Clear
                  </Button>
                </Col>
              </Row>
          </Col>
          {/* <Col size={1}></Col> */}
        </Row>
      </Container>
    </>
  )
}

export default CaseConverter