import React, { RefObject } from 'react'
import Menu from '../Menu/Menu'
import Footer from '../Footer/Footer'
import { Main } from './styles'

interface PageContainerProps {
  children: React.ReactElement;
  tutorialRef?: RefObject<HTMLDivElement>;
}

const PageContainer = ({ children, tutorialRef } : PageContainerProps) => {
  return (
    <>
      <Menu tutorialRef={tutorialRef} />
      <Main>
        {children}
      </Main>
      <Footer />
    </>
  )
}

export default PageContainer