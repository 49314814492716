import React, { useRef } from 'react';
import PageContainer from '../components/ui/PageContainer/PageContainer';
import CaseConverter from '../components/CaseConverter/CaseConverter';
import Tutorial from '../components/CaseConverter/Tutorial/Tutorial';

export const Home: React.FC = () => {
  const tutorialRef = useRef<HTMLDivElement>(null);

  return (
    <PageContainer tutorialRef={tutorialRef}>
      <>
        <CaseConverter />
        <Tutorial tutorialRef={tutorialRef} />
      </>
    </PageContainer>
  )
}

export default Home