import styled from 'styled-components'

export const Main = styled.main`
  width: auto;
  background-color: var(--grayLight);
  color: var(--black);
  flex: 1;
  padding-top: 2rem;
  padding-left: 3%;
  padding-right: 4.6%;
`;