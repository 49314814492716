import styled from 'styled-components'

export const Container = styled.div`
  margin: 3rem 3rem 0 3rem;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 1.2rem;
  display: flex;
  max-height: 60px;
  box-sizing: border-box; /* Include padding and border in width */

  @media(max-width: 800px) {
    min-height: 70px;
    width: 94%;
    margin: 1rem 1rem 0 1rem;
  }
`
export const Logo = styled.div`
  width: 30%;

  img {
    max-height: 100%;
  }
`

export const Links = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  @media(max-width: 800px) {
    width: 100%;
    justify-content: right;
  }
`

export const LinkToTutorial = styled.div`
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    border-bottom: 3px solid var(--black);
  }
`

export const Action = styled.div`
  width: 30%;
`

// .Logo {
//   max-width: 230px;
// }
// @media (max-width: 800px) {
//   .Logo {
//     max-width: 105px;
//   }
// }

// .Menu {
//   width: 100%;
//   height: 70px;
//   z-index: 100;

//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   padding-left: 5%;
//   padding-right: 5%;
//   padding-top: 1%;

//   background: var(--white);
//   border-bottom: 2px solid var(--greyMedium);
// }
// body {
//   --bodyPaddingTop: 94px;
//   padding-top: var(--bodyPaddingTop);
// }
// @media (max-width: 800px) {
//   .Menu {
//     height: 40px;
//     justify-content: center;
//   }
//   body {
//     --bodyPaddingTop: 40px;
//     padding-top: var(--bodyPaddingTop);
//   }
// }

// .ButtonLink {
//   color: var(--white);
//   border: 1px solid var(--white);
//   box-sizing: border-box;
//   cursor: pointer;
//   padding: 16px 24px;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   outline: none;
//   border-radius: 5px;
//   text-decoration: none;
//   display: inline-block;
//   transition: opacity .3s;
// }
// .ButtonLink:hover,
// .ButtonLink:focus {
//   opacity: .5;
// }

// @media (max-width: 800px) {
//   a.ButtonLink {
//     position: fixed;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: var(--primary);
//     border-radius: 0;
//     border: 0;
//     text-align: center;
//   }
// }